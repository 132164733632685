import { render, staticRenderFns } from "./salesTable.vue?vue&type=template&id=717d411d&scoped=true&"
import script from "./salesTable.vue?vue&type=script&lang=js&"
export * from "./salesTable.vue?vue&type=script&lang=js&"
import style0 from "./salesTable.vue?vue&type=style&index=0&id=717d411d&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "717d411d",
  null
  
)

export default component.exports