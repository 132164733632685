<template>
  <el-main>
    <el-form class="el-form-search" label-width="115px">
      <el-form-item label="退货单号：">
        <el-input size="small" placeholder="请输入退货单号" v-model="keywords_type.refund_sn" @input="serarchType(1, $event)"></el-input>
      </el-form-item>
      <el-form-item label="订单编号：">
        <el-input size="small" placeholder="请输入订单编号" v-model="keywords_type.order_no" @input="serarchType(2, $event)"></el-input>
      </el-form-item>
      <el-form-item label="申请时间：">
        <el-date-picker v-model="timeVal" @change="getTime" size="small" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label="商品名称：">
        <el-input size="small" placeholder="请输入商品名称" v-model="keywords_type.goods_name" @input="serarchType(3, $event)"></el-input>
      </el-form-item>
      <el-form-item label="支付方式：">
        <el-select v-model="form.payment_code" size="small" placeholder="请选择">
          <el-option v-for="item in pay_type" :key="item.val" :label="item.label" :value="item.val"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="收货人姓名：">
        <el-input size="small" placeholder="请输入收货人姓名" v-model="keywords_type.receiver_name" @input="serarchType(4, $event)"></el-input>
      </el-form-item>
      <el-form-item label="收货人手机号：">
        <el-input size="small" placeholder="请输入收货人手机" v-model="keywords_type.receiver_phone" @input="serarchType(5, $event)"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="40px">
        <el-button type="primary" size="small" @click="serarch">搜索</el-button>
        <el-button type="text" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-tabs type="card" v-model="form.refund_state" @tab-click="tabClick(tab_type)">
      <el-tab-pane name="0">
        <span slot="label">全部订单（{{ refundNum.state_all }}）</span>
      </el-tab-pane>
      <el-tab-pane name="1">
        <span slot="label">待处理（{{ refundNum.state_handle }}）</span>
      </el-tab-pane>
      <el-tab-pane name="2">
        <span slot="label">待买家发货（{{ refundNum.state_send }}）</span>
      </el-tab-pane>
      <el-tab-pane name="3">
        <span slot="label">待商家收货（{{ refundNum.state_receiving }}）</span>
      </el-tab-pane>
      <el-tab-pane name="4">
        <span slot="label">确认收货并退款（{{ refundNum.state_receiving_refund }}）</span>
      </el-tab-pane>
      <el-tab-pane name="5">
        <span slot="label">退货退款成功（{{ refundNum.state_success }}）</span>
      </el-tab-pane>
      <el-tab-pane name="6">
        <span slot="label">退款关闭（{{ refundNum.state_close }}）</span>
      </el-tab-pane>
      <el-tab-pane name="7">
        <span slot="label">退款成功（{{ refundNum.state_noly_success }}）</span>
      </el-tab-pane>
      <el-tab-pane name="8">
        <span slot="label">申请驳回（{{ refundNum.state_refuse }}）</span>
      </el-tab-pane>
      <el-tab-pane name="9">
        <span slot="label">待补款订单（{{ refundNum.state_refund_money }}）</span>
      </el-tab-pane>
    </el-tabs>
    <SalesRefund ref="salesRefund" :content="form"></SalesRefund>
  </el-main>
</template>

<script>
import SalesRefund from './components/salesTable';
export default {
  components: {
    SalesRefund
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      form: {
        page: 1,
        rows: 10,
        payment_code: 'whole', //支付方式
        keywords_type: '', //order_no — 订单号 receiver_phone 收货人电话 receiver_name 收货人姓名 goods_name 商品名称 refund_sn 退款订单号 store_id 店铺id
        keywords: '', //搜索内容
        create_time: [], //下单时间
        refund_state: 0, // 0 全部 1 申请退款，等待商家确认 2同意申请，等待买家退货 3买家已发货，等待收货 4已收货，确认退款 5退款成功 6退款关闭 7同意退款，仅退款 8拒绝(驳回)
      },
      keywords_type: {
        refund_sn: '',
        order_no: '',
        goods_name: '',
        receiver_phone: '',
        receiver_name: '',
        store_id: '',
      },
      pay_type: [
        {
          val: 'whole',
          label: '全部',
        },
        {
          val: 10,
          label: '微信支付',
        },
        {
          val: 20,
          label: '支付宝支付',
        },
        {
          val: 30,
          label: '华为支付',
        },
        {
          val: 40,
          label: '余额支付',
        },
        {
          val: 50,
          label: '会员卡支付',
        },
      ],
      timeVal: '',
      refundNum: {},
    };
  },

  created() {
    if (this.$route.query.type && this.$route.query.type == 1) {
      this.form.refund_state = '1';
    }
    this.getRefundNum();
  },
  methods: {
    //搜索
    serarch() {
      this.$refs.salesRefund.getList();
      this.getRefundNum();
    },
    //清空搜索
    cancelSearch() {
      for (let i in this.keywords_type) this.keywords_type[i] = '';
      this.timeVal = '';
      this.form.payment_code = 'whole';
      this.form.keywords_type = '';
      this.form.keywords = '';
      this.form.create_time = [];
      this.getRefundNum();
      this.$refs.salesRefund.getList();
    },
    tabClick(val) {
      this.$refs.salesRefund.page = 1;
      this.$refs.salesRefund.rows = 10;
      this.$refs.salesRefund.getList();
    },
    //获取订单数量
    getRefundNum() {
      let form = this.form;
      let data = {
        page: form.page,
        rows: form.rows,
        create_time: form.create_time,
      };
      if (form.payment_code != 'whole') {
        data.payment_code = form.payment_code;
      }
      if (form.refund_state > 0) {
        data.refund_state = form.refund_state;
      }
      if (form.keywords) {
        data.keywords_type = form.keywords_type;
        data.keywords = form.keywords;
      }
      this.$axios.post(this.$api.serviceProvider.companyOrderStateNum, data).then((res) => {
        if (res.code == 0) {
          this.refundNum = res.result;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //处理搜索内容
    serarchType(type, val) {
      let keywords_type = this.keywords_type;
      let form = this.form;
      form.keywords = val;
      for (let i in keywords_type) keywords_type[i] = '';
      switch (type) {
        case 1:
          keywords_type.refund_sn = val;
          form.keywords_type = 'refund_sn';
          break;
        case 2:
          keywords_type.order_no = val;
          form.keywords_type = 'order_no';
          break;
        case 3:
          keywords_type.goods_name = val;
          form.keywords_type = 'goods_name';
          break;
        case 4:
          keywords_type.receiver_name = val;
          form.keywords_type = 'receiver_name';
          break;
        case 5:
          keywords_type.receiver_phone = val;
          form.keywords_type = 'receiver_phone';
          break;
        case 6:
          keywords_type.store_id = val;
          form.keywords_type = 'store_id';
          break;
      }
    },
    //获取申请时间
    getTime(val) {
      this.form.create_time = [];
      for (let i in val) this.form.create_time.push(val[i].getTime() / 1000);
      if (this.form.create_time[0] == this.form.create_time[1]) this.form.create_time[1] = this.form.create_time[1] + 86400;
    },
    getList() {
      this.$axios(this.$api.getList, this.data).then(res => {
        console.log(res);
      })
    }
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>
